<template>
  <div >
    <SearchWidget @searchOrder="getFetchOrders" />
    <b-row v-if="form.orders != ''">
      <b-col>
        <OrdersTableWidget
          :orders="form.orders"
          :title="'Orders Cancelled'"
        ></OrdersTableWidget>
      </b-col>
    </b-row>
    <div class="card-body"  v-else>
      <center><h2 class="font-poppins">No Orders for Today {{orders}}</h2></center>

    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SearchWidget from "@/view/pages/orders/components/SearchWidget.vue";
import TotalOrdersWidget from "@/view/pages/orders/components/TotalOrdersWidget.vue";
import AcceptedOrdersWidget from "@/view/pages/orders/components/AcceptedOrdersWidget.vue";
import CancelledOrdersWidget from "@/view/pages/orders/components/CancelledOrdersWidget.vue";
import OrdersTableWidget from "@/view/pages/orders/components/OrdersTableWidget.vue";
import { ORDERS } from "@/core/services/store/sales.module";
import { mapGetters } from "vuex";

export default {
  name: "TodayOrders",
  computed: {
    ...mapGetters(["currentUser" , "getDate"  , "cancelled"]),
  },
  components: {
    TotalOrdersWidget,
    SearchWidget,
    AcceptedOrdersWidget,
    CancelledOrdersWidget,
    OrdersTableWidget,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Orders" },
      { title: "History" },
      { title: "Cancelled Orders" },
    ]);

    // look for current user
    if (this.currentUser.token !== "") {
      // call method to get orders
      this.fetchOrders({});
    }
  },

  data() {
    return {
      form: {
        orders: [],
      },
    };
  },
  methods: {
    getFetchOrders(obj){
      if  (obj.start && obj.end)
      {
        this.fetchOrders(obj)
      }
    },
    fetchOrders(obj) {
      obj.status = this.cancelled 
      this.$store
        .dispatch(ORDERS, obj)
        // go to which page after successfully login
        .then((resp) => {
          this.form.orders = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
